<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="data"
      :loading="loading"
      hide-default-footer
      :items-per-page="24"
      class="elevation-1 text-overflow my-10"
      single-select
      show-select
      @click:row="editTemplate"
    >
      <!-- style="cursor:pointer" -->
      <template v-slot:top>
        <v-toolbar flat height="70">
          <!-- <v-row class="mt-4"> -->
          <v-col cols="4" class="pl-0">
            <v-row justify="start" class="ml-1">
              <v-toolbar-title class="headline" :data-cy="title">
                <span>
                  {{ title }}
                </span>
              </v-toolbar-title>
            </v-row>
          </v-col>
          <v-col cols="4" class="mt-4">
            <v-row justify="center">
              <v-col cols="6">
                <v-text-field
                  class="mx-4"
                  v-model="search"
                  label="Search"
                  single-line
                  hide-details
                  color="primary"
                  @input="submitSearch"
                />
              </v-col>
              <!-- <v-col cols="6">
                <v-select
                  class="mx-4"
                  v-model="filterSelected"
                  :items="filterOptions"
                  item-text="name"
                  item-value="value"
                  color="primary"
                ></v-select>
              </v-col> -->
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-row justify="end" class="mr-1">
              <v-btn
                color="primary"
                dark
                text
                data-cy="builderAddNewBtn"
                @click="newTemplate()"
                >Add new
              </v-btn>
            </v-row>
          </v-col>
          <!-- </v-row> -->
        </v-toolbar>
      </template>

      <template v-slot:item.name="{ item }">
        <div>
          {{ getTemplateName(item) }}
        </div>
      </template>

      <template v-slot:item.boat="{ item }">
        <div>
          {{ getBoatText(item) }}
        </div>
      </template>

      <template v-slot:item.type="{ item }">
        <div>
          {{ getTypeText(item) }}
        </div>
      </template>

      <template v-slot:item.crm_group="{ item }">
        <div>
          {{ item.crm_group.name }}
        </div>
      </template>

      <template v-slot:item.action="{ item }">
        <div style="height:40px" class="pt-2" onclick="event.stopPropagation()">
          <v-icon class="mx-2 delete--hover" @click="removeTemplate(item)"
            >mdi-delete
          </v-icon>
          <!-- <v-icon class="mx-2 edit--hover" @click="editTemplate(item)"
            >mdi-pencil
          </v-icon> -->
          <v-tooltip bottom v-if="showActions">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mx-2 edit--hover"
                v-bind="attrs"
                v-on="on"
                @click="createTask(selected, item)"
                >{{ generateCreateTaskIcon(item.uuid) }}
              </v-icon>
            </template>
            <span>Create task from checklist</span>
          </v-tooltip>

          <v-tooltip bottom v-if="showActions">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mx-2 edit--hover"
                v-bind="attrs"
                v-on="on"
                @click="duplicateTemplate(item)"
                >mdi-content-duplicate
              </v-icon>
            </template>
            <span>Duplicate checklist</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.data-table-select="{ item }">
        <div class="pt-2" onclick="event.stopPropagation()">
          <v-checkbox
            color="primary"
            v-model="selected"
            :value="item.uuid"
          ></v-checkbox>
        </div>
      </template>
    </v-data-table>
    <v-pagination
      v-model="currentPage"
      :length="numberOfPages"
      :total-visible="7"
      color="primary"
    />
  </div>
</template>

<script>
import debounce from "@/plugins/debounce";
import api from "@/utils/api";

export default {
  props: {
    title: String,
    headers: Array,
    data: Array,
    sort: String,
    loading: Boolean,
    response: Object,
    createTaskTarget: {
      type: String,
      default: "tasksNew",
    },
  },

  components: {},

  data: () => ({
    search: "",
    page: 1,
    selected: [],
  }),
  created() {
    if (this.$route.query.page || this.$route.query.search) {
      this.search = this.$route.query.search;
      this.currentPage = Number(this.$route.query.page);
    }
  },

  computed: {
    numberOfPages() {
      if (!this.response) {
        return 0;
      }
      return parseInt(Math.ceil(this.response.count / 24));
    },
    currentPage: {
      get: function() {
        return this.page;
      },
      set: function(value) {
        this.page = value;
        this.$emit("reload", { search: this.search, page: value });
        this.$router.replace({
          name: this.$route.name,
          query: {
            page: this.page,
            search: this.search,
          },
        });
      },
    },
    showActions() {
      return this.$route.name !== "Inventory";
    },
  },
  methods: {
    duplicateTemplate(item) {
      api.duplicateTemplate(item.uuid).then((resp) => {
        this.$router.go();
      });
    },
    getBoatText(item) {
      if (item.checklists.length === 0) {
        return "";
      }
      return item.checklists[0].crm_boat === null
        ? "Any"
        : item.checklists[0].crm_boat.boat_name;
    },
    getTypeText(item) {
      if (item.checklists.length === 0) {
        return "";
      }
      return item.checklists[0].checklist_type === null ? "Team" : "Guest";
    },
    getTemplateName(item) {
      if (item.checklists.length === 0) {
        return "";
      }
      return item.checklists[0].name;
    },
    generateCreateTaskIcon(uuid) {
      if (
        this.selected.length >= 2 &&
        this.selected.find((selectedUuid) => selectedUuid === uuid)
      ) {
        return "mdi-checkbox-multiple-marked-circle-outline";
      } else {
        return "mdi-calendar-plus";
      }
    },

    createTask(selected, template) {
      if (selected.length >= 2) {
        let templates = this.data.filter((element) =>
          selected.includes(element.uuid)
        );
        this.$router.push({ name: this.createTaskTarget }).then(() => {
          this.$store.commit("SET_SERVICES", {
            from: "template",
            services: templates,
          });
        });
      } else {
        this.$router.push({ name: this.createTaskTarget }).then(() => {
          this.$store.commit("SET_SERVICES", {
            from: "template",
            services: [template],
          });
        });
      }
    },

    submitSearch(event) {
      this.page = 1;
      this.$router.replace({
        name: this.$route.name,
        query: {
          page: this.page,
          search: this.search,
        },
      });
      this.$emit("reload", { search: this.search, page: this.page });
      return false;
    },
    newTemplate() {
      this.$emit("new", {});
    },
    editTemplate(item) {
      this.$emit("edit", item);
    },
    removeTemplate(item) {
      this.$emit("remove", item);
    },
  },
};
</script>

<style lang="scss">
.delete--hover {
  &:hover {
    color: red;
  }
}

.preview--hover {
  &:hover {
    color: var(--primary);
  }
}

.edit--hover {
  &:hover {
    color: var(--primary);
  }
}
</style>
