<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent width="400">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete {{ item }}?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onDisagree()">No</v-btn>
          <v-btn color="red" text @click="onAgree()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: ["dialog", "item"],
  methods: {
    onAgree() {
      this.$emit("agree", true);
    },
    onDisagree() {
      this.$emit("agree", false);
    },
  },
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>
