<template>
  <v-container>
    <tasks-templates-list
      :title="title"
      :headers="headers"
      :data="data"
      :response="response"
      :loading="loading"
      :create-task-target="'TasksNew'"
      @reload="loadAutomations"
      @new="newFn"
      @edit="editFn"
      @remove="removeFn"
    ></tasks-templates-list>
    <delete-dialog
      :dialog="deleteDialog"
      :item="automationName"
      @agree="removeHandler"
      v-show="false"
    />
  </v-container>
</template>

<script>
import api from "@/utils/api.js";
import TasksTemplatesList from "@/components/lists/TemplatesList.vue";
import snackbar from "@/utils/snackbar.js";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import { throws } from "assert";
export default {
  components: {
    TasksTemplatesList,
    DeleteDialog,
  },
  data: () => ({
    headers: [
      {
        text: "Title",
        align: "left",
        value: "title",
      },
      {
        text: "Trigger",
        align: "left",
        value: "trigger",
      },
      {
        text: "Type",
        align: "left",
        value: "type_of_automation",
      },
      {
        text: "Target audience",
        align: "left",
        value: "target_audience",
      },
      {
        text: "Visibility",
        align: "left",
        value: "crm_group",
      },
      { text: "", align: "right", value: "action", sortable: false },
    ],
    data: [],
    sort: "name",
    title: "Automations",
    deleteDialog: false,
    automationName: null,
    automation: {},
    loading: true,
    response: null,
  }),
  created() {
    if (this.$route.query.page || this.$route.query.search) {
      this.loadAutomations(this.$route.query);
    } else {
      this.loadAutomations();
    }
  },
  methods: {
    loadAutomations(params) {
      if (!params) {
        params = {};
      }
      this.loading = true;
      this.data = [];
      api.getAutomationsList(params).then((resp) => {
        // this.response = resp.data;
        // this.data = resp.data.results;
        this.data = resp;
        this.loading = false;
      });
    },

    editFn(task) {
      this.$router.push({
        name: "AutomationsEdit",
        params: { id: task.uuid },
      });
    },

    removeFn(automation) {},

    removeHandler(agreed) {
      // if (agreed) {
      //   // api.deleteTemplate(this.automation.uuid).then((resp) => {
      //   // snackbar.success("Successfully deleted");
      //   // this.loadAutomations(this.$route.query);
      //   // });
      //   const index = this.data.find((item) => {
      //     this.automation.uuid === item.uuid;
      //   });
      //   this.data.splice(index, 1);
      // }
      // this.deleteDialog = false;
    },

    newFn(task) {
      this.$router.push({
        name: "AutomationsNew",
      });
    },
  },
};
</script>
