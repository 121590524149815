var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 text-overflow my-10",attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.loading,"hide-default-footer":"","items-per-page":24,"single-select":"","show-select":""},on:{"click:row":_vm.editTemplate},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","height":"70"}},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"4"}},[_c('v-row',{staticClass:"ml-1",attrs:{"justify":"start"}},[_c('v-toolbar-title',{staticClass:"headline",attrs:{"data-cy":_vm.title}},[_c('span',[_vm._v(" "+_vm._s(_vm.title)+" ")])])],1)],1),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"4"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search","single-line":"","hide-details":"","color":"primary"},on:{"input":_vm.submitSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',{staticClass:"mr-1",attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"color":"primary","dark":"","text":"","data-cy":"builderAddNewBtn"},on:{"click":function($event){return _vm.newTemplate()}}},[_vm._v("Add new ")])],1)],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getTemplateName(item))+" ")])]}},{key:"item.boat",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getBoatText(item))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getTypeText(item))+" ")])]}},{key:"item.crm_group",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.crm_group.name)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-2",staticStyle:{"height":"40px"},attrs:{"onclick":"event.stopPropagation()"}},[_c('v-icon',{staticClass:"mx-2 delete--hover",on:{"click":function($event){return _vm.removeTemplate(item)}}},[_vm._v("mdi-delete ")]),(_vm.showActions)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2 edit--hover",on:{"click":function($event){return _vm.createTask(_vm.selected, item)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.generateCreateTaskIcon(item.uuid))+" ")])]}}],null,true)},[_c('span',[_vm._v("Create task from checklist")])]):_vm._e(),(_vm.showActions)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2 edit--hover",on:{"click":function($event){return _vm.duplicateTemplate(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-content-duplicate ")])]}}],null,true)},[_c('span',[_vm._v("Duplicate checklist")])]):_vm._e()],1)]}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-2",attrs:{"onclick":"event.stopPropagation()"}},[_c('v-checkbox',{attrs:{"color":"primary","value":item.uuid},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]}}])}),_c('v-pagination',{attrs:{"length":_vm.numberOfPages,"total-visible":7,"color":"primary"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }